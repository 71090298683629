import React, { createContext, useContext } from 'react';
import { LocalStorageFunctions, LocalStorageProviderProps } from "./types";

const LocalStorageContext: React.Context<LocalStorageFunctions | undefined> = createContext<LocalStorageFunctions | undefined>(undefined);

export const useLocalStorage = (): LocalStorageFunctions => {
  const context = useContext(LocalStorageContext);

  if (!context) {
    throw new Error('useLocalStorage must be used within a LocalStorageProvider');
  }

  return context;
};

export const LocalStorageProvider: React.FC<LocalStorageProviderProps> = ({children}) => {
  const getItem = (key: string) => {
    const val = window.localStorage.getItem(key);
    return val ? JSON.parse(val) : null;
  };

  const setItem = (key: string, value: any): void => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  const removeItem = (key: string): void => {
    window.localStorage.removeItem(key);
  };

  const localStorageFunctions: LocalStorageFunctions = {
    getItem,
    setItem,
    removeItem,
  }; 

  return (
    <LocalStorageContext.Provider value={localStorageFunctions}>
      {children}
    </LocalStorageContext.Provider>
  );
};
