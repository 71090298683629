import React, { useState, useEffect, useCallback, ReactElement } from 'react';
import jwt from 'jsonwebtoken';
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/ui/Loader";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ToolbarWrapper from "../../components/ui/ToolbarWrapper";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as AuthActions from "../../modules/Auth/AuthAction";
import { useLocalStorage } from "../../services/LocalStorageService";
import * as UserActions from "../../modules/User/UserAction";

const SurveyView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [renderFrame, setRenderFrame] = useState(false);
  const [hasBeenRedirected, setHasBeenRedirected] = useState(false);
  const [url, setUrl] = useState<any>(null);
  const authToken = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.user.id);
  const dispatch = useDispatch();
  const localStorageFunctions = useLocalStorage();
  const onMessage = useCallback((event: any): void => {
    try {
      const { action, message } = JSON.parse(event.data);

      toast.success(message, {position: "bottom-center"});

      if (action === 'done') {
        navigate("/survey");
      } else if (action === 'logout') {
        dispatch(AuthActions.authCleanData());
        localStorageFunctions.removeItem("token");
        navigate("/");
      } else {
        navigate("/");
      }
    } catch (error) {
      if (!(error instanceof SyntaxError && error.message.includes("[object Object]"))) {
        console.error("Wystąpił błąd: ", error);
      }
    }
  }, [dispatch, localStorageFunctions, navigate]);

  useEffect(() => {
    if (!!user && !!authToken) {
      jwt.sign(
        {
          iss: 'BiostatSurveyApp',
          exp:  new Date().getTime() + 5 * 60 * 1000,
          user_id: user,
        },
        authToken,
        {
          algorithm: 'HS256',
        },
        (err, token) => {
          if (err) {
            console.error(err);
          } else {
            setUrl(`${location.state.url}?token_jvt=${token}`);
            setRenderFrame(true);
          }
        }
      );
    } else {
      dispatch(UserActions.userAction());
    }
  }, [authToken, dispatch, location.state.url, user]);

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, [onMessage]);

  useEffect(() => {
    if (hasBeenRedirected) {
      navigate('/survey');
    }
  }, [hasBeenRedirected, location.pathname, navigate]);

  const openSurvey = (url: string): ReactElement => {
    window.location.href = url;

    setHasBeenRedirected(true);

    return <></>;
  }

  const isIOS = (): boolean => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  return (
    <ToolbarWrapper hasToolbar={true} toolbarConfig={{
      leftItems: <IconButton sx={{color: "#009688"}} onClick={() => {navigate("/survey")}}><ArrowBackIosNewIcon/></IconButton>,
      text: "Ankieta"
    }}>
      {renderFrame ? (
        (isIOS() || !location.state.external) ? (
          <iframe
            id={"iframeSurvey"}
            title="Survey"
            src={url}
            style={{width: '100%', height: '100vh', border: 'none'}}
          ></iframe>
        ) : (
          !hasBeenRedirected ? openSurvey(url) : <></>
        )
      ) : (
        <Loader/>
      )}
    </ToolbarWrapper>
  );
};

export default SurveyView;
